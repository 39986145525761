<template>
    <div class="visitorList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">灯塔列表</div>
        </div>
        <div class="case-wrap">
            <div class="tabs-wrap">
                <el-tabs v-model="tagName" type="card" @tab-click="handleClick" style="display: inline-block;">
                    <el-tab-pane v-for="(item, index) in tagList" :key="index" :label="item.name" :name="item.id">
                    </el-tab-pane>
                </el-tabs>
                <el-tabs v-model="intentionName" type="card" @tab-click="handleClick2" style="display: inline-block;">
                    <el-tab-pane v-for="(item, index) in intentionList" :key="index" :label="item.name" :name="item.id">
                    </el-tab-pane>
                </el-tabs>
                <div class="btn-wrap">
                    <el-button :loading="isDowing" @click="derivedForm" type="warning">导出</el-button>
                </div>
            </div>
            <div class="screen-wrap">
                <div class="chage-date">
                    <commonSelect @changeDate="changeDate" @clickTagDate="clickTagDate"></commonSelect>
                </div>
                <div class="screen-item">
                    <div class="si-title">归属人</div>
                    <el-select clearable @change="belongerChange" v-model="belonger" placeholder="请选择归属人">
                        <el-option v-for="item in belongerOptions" :key="item.id" :label="item.username" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="table-wrap">
                <el-table ref="multipleTable" :data="tableData" border style="width: 100%" height="615" v-loading="loading">
                    <el-table-column align="center" prop="TagName" label="标签"></el-table-column>
                    <el-table-column align="center" prop="contacts_name" label="姓名"></el-table-column>
                    <el-table-column align="center" prop="contacts_mobile" label="联系方式"></el-table-column>
                    <el-table-column align="center" prop="operating_brand" label="经营品牌"></el-table-column>
                    <el-table-column align="center" prop="ImgsArray" label="上传图片" width="200">
                        <template slot-scope="scope">
                            <div v-if="scope.row.ImgsArray.length > 0">
                                <div class="table-image-tag" v-if="scope.row.ImgsArray.length > 1">{{ scope.row.ImgsArray.length
                                }}张</div>
                                <img class="table-image" :src="scope.row.ImgsArray[0]" @click="clickImage(scope.row)" />
                            </div>
                            <div v-else>暂无</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="addressAll" label="所在地区"></el-table-column>
                    <el-table-column align="center" prop="create_timex" label="创建时间"></el-table-column>
                    <el-table-column align="center" prop="UserName" label="归属人"></el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.last_record_time" @click="followRecord(scope.row)" size="mini" type="primary">跟进记录</el-button>
                            <el-button v-else  size="mini" type="info" plain disabled>跟进记录</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                        layout="total, prev, pager, next" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>

        <!-- 点击图片多图轮播显示 -->
        <div class="dialog-imageVideo-list">
            <!-- 图片 -->
            <div v-if="isShowImages">
                <el-image-viewer style="z-index: 99999" :on-close="closeViewer" :url-list="imageList" />
            </div>
            <el-dialog class="dialog-wrap" :close-on-click-modal="false" width="24%" center>
            </el-dialog>
        </div>

        <!-- 跟进记录 -->
        <el-dialog :visible.sync="isShowInfo" 
            :before-close="handleClose"
            custom-class="dialog-show-wrap"
            :close-on-click-modal="false">
            <div class="title">跟进记录</div>
            <div class="content">
                <el-table :data="recordData" border style="width: 100%" v-loading="recordLoading" 
                    height="540">
                    <el-table-column align="center" prop="create_timex" label="时间"></el-table-column>
                    <el-table-column align="center" prop="contents" label="跟进内容" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="writeUser.username" label="跟进人" :show-overflow-tooltip="true"></el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="recordCurrentChange"
                        :current-page.sync="recordPage"
                        :page-size="10"
                        layout="total, prev, pager, next"
                        :total="recordTotal">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import commonSelect from "@/components/commonSelect.vue"
import pageMixin from '@/internal/pageMixin'
import { dealdate } from "@/utils/timeCycle";
import common from "@/utils/common.js"
import axios from "axios";
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: {
        commonSelect,
        ElImageViewer
    },
    mixins: [pageMixin],
    data() {
        return {
            pageMixin_index: 'lighthouseList',

            tableData: [],
            currentPage: 1,
            total: 0,

            tagList: [],
            tagName: '',
            tagId: '',

            intentionList: [],
            intentionName: '',
            intentionId: '',

            belongerOptions: [],
            belonger: '',

            dateType: '',
            startDate: '',
            endDate: '',

            imageList: [],
            isShowImages: false,

            loading: false,
            isDowing: false,

            row_id:'',
            isShowInfo: false,
            recordData: [],
            recordPage: 1,
            recordTotal: 0,
            recordLoading: false,

        }
    },
    mounted() {
        this.getInit();
        this.getList();
    },
    methods: {
        handleClose(){
            this.isShowInfo = false;
        },
        // 获取列表数据
        getRecordList(){
            this.recordLoading = true;
            let params = {
                lighthouse_id: this.row_id,
                page: this.recordPage,
            }
           common.connect('/distributorpcv1/Lighthouse/getRecordList',params,(res)=>{
                this.recordData = res.data.list;
                this.recordTotal = res.data.count;
                this.recordLoading = false;
            }) 
        },
        recordCurrentChange(val){
            this.recordPage = val;
            this.getRecordList();
        },
        // 跟进记录
        followRecord(row){
            this.isShowInfo = true;
            this.info = row;
            this.row_id = row.id;
            this.getRecordList();
        },
        // 初始化筛选条件
        getInit() {
            common.connect('/distributorpcv1/Lighthouse/getInit', {}, (res) => {
                this.belongerOptions = res.data.u_list;//下属
                this.tagList = res.data.tag_list;//标签
                let quan = {
                    id:'',
                    name: '全部',
                    label: ''
                }
                this.tagList.unshift(quan)
                this.intentionList = [
                  {
                    id: 0,
                    name: '全部'
                  },
                  {
                    id: 1,
                    name: '意向较为明确'
                  },
                  {
                    id: 2,
                    name: '有意向，但在观望'
                  },
                  {
                    id: 3,
                    name: '没太大意向'
                  },
                ];
            })
        },
        // 获取列表数据
        getList() {
            this.loading = true;
            let params = {
                type: 1,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                tag: this.tagId,
                intention: this.intentionId,
                uid: this.belonger,
                page: this.currentPage
            }
            common.connect('/distributorpcv1/Lighthouse/getLighthouseList', params, (res) => {
                this.tableData = res.data.list;
                this.total = res.data.count;
                this.loading = false;
            })
        },
        //tab切换，标签
        handleClick(tab, event) {
            console.log(tab);
            this.tagId = tab.name;
            this.currentPage = 1;
            this.getList();
        },
        //tab切换，标签
        handleClick2(tab, event) {
            this.intentionId = tab.name;
            this.currentPage = 1;
            this.getList();
        },
        // 归属人筛选
        belongerChange() {
            this.currentPage = 1;
            this.getList();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        // 日期选择
        changeDate(startDate, endDate) {
            this.currentPage = 1;
            this.startDate = startDate.slice(0, 11) + '00:00:01';
            this.endDate = endDate.slice(0, 11) + '23:59:59';
            this.getList()
        },
        clickTagDate(item) {
            this.dateType = item.label;
            this.currentPage = 1;
            if (item.label != 'date') {
                this.startDate = '';
                this.endDate = '';
                this.getList();
            }
        },

        // 点击图片预览
        clickImage(row) {
            this.imageList = row.ImgsArray;
            this.isShowImages = true;
        },
        // 关闭图片预览查看器
        closeViewer() {
            this.isShowImages = false;
        },

        // 导出
        async derivedForm() {
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            if (this.tableData.length == 0) {
                this.$message.warning('暂无数据可以导出');
                return
            }
            this.isDowing = true;
            let params = {
                type: 1,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
                tag: this.tagId,
                uid: this.belonger,
            }
            let url = common.apiServer + '/distributorpcv1/Lighthouse/export'
            axios({
                url: url,
                responseType: "blob",
                method: 'POST',
                data: params,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorization,
                }
            }).then(function (res) {
                let day = dealdate(new Date()).slice(0, 10)
                const blob = new Blob([res.data]);
                const fileName = '灯塔列表数据' + day + '.xls';
                const linkNode = document.createElement('a');
                linkNode.download = fileName; //a标签的download属性规定下载文件的名称
                linkNode.style.display = 'none';
                linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
                document.body.appendChild(linkNode);
                linkNode.click();  //模拟在按钮上的一次鼠标单击
                URL.revokeObjectURL(linkNode.href); // 释放URL 对象
                document.body.removeChild(linkNode);
            })
            setTimeout(() => {
                this.isDowing = false;
            }, 2000)
        },
        


    },
}
</script>

<style lang='scss'>
.dialog-imageVideo-list {
    .dialog-wrap {
        margin: 10px 0;

        // .el-carousel__container{
        //     height: 600px;  
        // }
        .video-wrap {
            width: 100% !important;
            height: 100% !important;
        }

        .imageList {
            width: 100%;
            height: 100%;
        }
    }

    .el-dialog__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .el-dialog {
        margin-top: 5vh !important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-content: center;
    }
}

.visitorList {
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;

    .title-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;

        .tag {
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }

    .case-wrap {
        background: #fff;
        margin-top: 20px;
        border-radius: 6px;
        position: relative;

        .el-tabs__header {
            padding-top: 20px;
            margin: 0 20px;
        }

        .btn-wrap {
            position: absolute;
            right: 20px;
            top: 15px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }

        .screen-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;

            .screen-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .si-title {
                    font-size: 14px;
                    padding-right: 10px;
                }
            }
        }

        .table-wrap {
            padding: 0 20px 0px 20px;

            .pagination-wrap {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 6px;
            }

            .table-image-tag {
                position: absolute;
                top: 0;
                left: 0;
                background: #72a5fe;
                color: #fff;
                font-size: 14px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: first baseline;
                -webkit-clip-path: polygon(30px 0px, 0px 0px, 0px 30px, 0px 30px);
            }

            .table-image {

                cursor: pointer;
                max-width: 50px;
                max-height: 40px;
            }
        }
    }


    .dialog-show-wrap {
        width: 700px;
        overflow: hidden;
        padding: 20px;

        .title {
            font-size: 17px;
            color: #000;
            font-weight: bold;
            margin-top: -30px;
        }

        .content {
            padding-top: 20px;

            .c-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;

                .tit {
                    width: 60px;
                    color: #000;
                    font-size: 15px;
                    text-align: justify;
                    text-align-last: justify;
                }

                .con {
                    width: calc(100% - 80px);
                    color: #666;
                    font-size: 15px;
                }
            }

            .pagination-wrap {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 6px;
            }
        }
    }

    .el-dialog {
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 10px;
    }
}
</style>
